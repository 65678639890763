import React, { useState } from "react";
import fileSystem from "./fileSystem.json";
import "./App.css";

function Terminal() {
  const [history, setHistory] = useState(["Welcome to the terminal!"]);
  const [currentDirectory, setCurrentDirectory] = useState("/");
  const [error, setError] = useState("");

  function updateHistory(newHistory) {
    setHistory([...history, newHistory]);
  }

  async function handleInput(event) {
    event.preventDefault();
    const input = event.target[0].value.trim();
    if (!input) {
      return;
    }
    event.target[0].value = "";
    const args = input.split(" ");
    setError([]);
    setHistory([history.push(`${strftime("%H:%M", Date.now())} ${currentDirectory} ➜ ${input}`)]);
    switch (args[0]) {
      case "ls":
      case "l":
        handleLs(args.slice(1));
        break;
      case "cd":
        handleCd(args.slice(1));
        break;
      case "cat":
        handleCat(args.slice(1));
        break;
      case "whois":
      case "about":
      case "aboutme":
        handleAbout();
        break;
      case "links":
      case "social":
      case "socials":
        handleLinks();
        break;
      case "portfolio":
        handlePortfolio();
        break;
      case "projects":
        handleProjects();
        break;
      case "clear":
        handleClear();
        break;
      case "help":
        handleHelp();
        break;
      case "ping":
        handlePing(args.slice(1));
        break;
      case "man":
        handleMan(args.slice(1));
        break;
      case "art":
        printAsciiArt();
        break;
      default:
        handleUnknownCommand();
    }
  }

  function handleAbout() {
    updateHistory("Hi there! I'm Danial.");
  }

  function handleLinks() {
    setHistory([...history,
      ...history,
      "Personal Website: https://website.danials.space/",
      "Articles: https://medium.danials.space/",
      "LinkedIn: https://www.linkedin.com/in/ali-reza-mohammadzadeh/",
      "GitHub: https://github.com/danial2026",
    ]);
  }

  function handlePortfolio() {
    updateHistory("My portfolio is available at:");
    updateHistory("danials.space");
  }

  function handleProjects() {
    updateHistory("My projects are:");
    updateHistory("project1");
    updateHistory("project2");
    updateHistory("project3");
  }

  function handleClear() {
    setHistory(["Welcome to the terminal!"]);
    setError([]);
    setCurrentDirectory("/");
  }

  function handleHelp() {
    setHistory([
      ...history,
      "The following commands are available:",
      "ls - List files and directories",
      "cd - Change directory",
      "cat - concatenate files and print on the standard output",
      "whois / about / aboutme - Show information about me",
      "links / social / socials - Show my social links",
      "portfolio - Show my portfolio",
      "projects - Show my projects",
      "clear - Clear the screen",
      "help - Show this help message",
      "man - Show manual for a command",
    ]);
  }

  function handleMan(args) {
    if (args.length !== 1) {
      updateHistory("man command requires one argument.");
      return;
    }
    switch (args[0]) {
      case "ls":
        updateHistory("ls - List files and directories");
        break;
      case "cd":
        updateHistory("cd DIR - Change directory");
        break;
      case "whois":
      case "about":
      case "aboutme":
        updateHistory("whois / about / aboutme - Show information about me");
        break;
      case "links":
      case "social":
      case "socials":
        updateHistory("links / social / socials - Show my social links");
        break;
      case "portfolio":
        updateHistory("portfolio - Show my portfolio");
        break;
      case "projects":
        updateHistory("projects - Show my projects");
        break;
      case "clear":
        updateHistory("clear - Clear the screen");
        break;
      case "help":
        updateHistory("help - Show this help message");
        break;
      case "man":
        updateHistory("man CMD - Show manual for a command");
        break;
      case "ping":
        updateHistory("ping - Pings a URL");
        break;
      case "cat":
        updateHistory("cat - concatenate files and print on the standard output");
        break;
      default:
        updateHistory("Unknown command for man.");
    }
  }

  function handleUnknownCommand() {
    updateHistory("ERROR");
    setError("Unknown command. Type `help` for a list of available commands.");
  }

  async function handleCd(args) {
    const targetDir = args[0];
    if (targetDir === ".") {
      return;
    }

    const targetDirObj = await findDir(targetDir);
    if (!targetDirObj) {
      updateHistory(`cd: '${targetDir}': No such file or directory`);
      return;
    }
    if (!targetDirObj.isDirectory) {
      updateHistory(`cd: '${targetDir}': Not a directory`);
      return;
    } 
    updateHistory(`${args}`);

    async function findDir(dir) {
      console.log(dir);
      if (dir === "/") {
        setCurrentDirectory("/");
        return fileSystem;
      }
      if (dir === "..") {
        const currentDirArr = currentDirectory.split("/");
        const newDirectoryArr = currentDirArr.slice(0, -1);
        const newDirectory = newDirectoryArr.length > 1 ? newDirectoryArr.join("/") : "/";
        setCurrentDirectory(newDirectory);
        return await findDir(newDirectory);
      }

      const dirArr = dir.split("/");
      var currentDirectoryArr;
      if (currentDirectory != "/") currentDirectoryArr = currentDirectory.split("/");
      else currentDirectoryArr = ["/"];
      let dirObj = fileSystem;
      console.log(`currentDirectory + dirArr : ${currentDirectory + "/" + dir}`);
      for (let i = 0; i < dirArr.length; i++) {
        console.log(`1: ${foundItem}`);
        var foundItem;
        if (dirObj.name.startsWith(currentDirectory)) foundItem = dirObj.items.find((item) => item.name === dirArr[i]);
        if (!foundItem) {
          console.log(`2: ${foundItem}`);
          foundItem = dirObj.items.find(
            (item) => item.items.find((item) => item.name === dirArr[i]) && currentDirectoryArr[1] == item.name
          );
          console.log(`3: ${foundItem}`);
        } else if (!foundItem) {
          console.log(`4: ${foundItem}`);
          foundItem = dirObj.items.find((item) =>
            item.items.find((item) => item.items.find((item) => item.name === dirArr[i]))
          );
          console.log(`5: ${foundItem}`);
        } else if (!foundItem) {
          console.log(`6: ${foundItem}`);
          foundItem = dirObj.items.find((item) =>
            item.items.find((item) => item.items.find((item) => item.items.find((item) => item.name === dirArr[i])))
          );
          console.log(`7: ${foundItem}`);
        }

        console.log(`foundItem: ${foundItem}`);
        if (!foundItem || !foundItem.isDirectory) {
          return null;
        }
        dirObj = foundItem;
      }
      var newDirectory = "";
      if (dirArr[0] == "/" || currentDirectory === "/") {
        newDirectory = "";
      } else {
        newDirectory = `${currentDirectory}`;
      }
      for (let i = 0; i < dirArr.length; i++) {
        newDirectory = `${newDirectory}/${dirArr[i]}`;
      }
      console.log(`newDirectory: ${newDirectory}`);
      setCurrentDirectory(newDirectory);
      console.log(`dirObj.name: /${dirObj.name}`);
      return dirObj;
    }
  }

  async function handleCat(args) {
    const fileName = args[0];
    const fileObj = await findFile(fileName);
    if (!fileObj) {
      updateHistory(`cat: ${fileName}: No such file or directory`);
      return;
    }
    if (fileObj.isDirectory) {
      updateHistory(`cat: ${fileName}: Is a directory`);
      return;
    }
    updateHistory(fileObj.content);

    async function findFile(file) {
      const dirArr = currentDirectory.split("/");
      let dirObj = fileSystem;
      for (let i = 1; i < dirArr.length; i++) {
        const foundItem = dirObj.items.find((item) => item.name === dirArr[i]);
        if (!foundItem || !foundItem.isDirectory) {
          return null;
        }
        dirObj = foundItem;
      }
      const foundFile = dirObj.items.find((item) => item.name === file);
      return foundFile;
    }
  }

  async function handleLs(args) {
    if (args.length > 1) {
      updateHistory("ls command takes at most one argument.");
      return;
    }

    const dir = args.length === 1 ? args[0] : currentDirectory;
    const dirObj = await findDir(dir);
    if (!dirObj) {
      updateHistory(`ls: cannot access '${dir}': No such file or directory`);
      return;
    }
    if (!dirObj.isDirectory) {
      updateHistory(`ls: cannot access '${dir}': Not a directory`);
      return;
    }

    const items = dirObj.items.map((item) => item.name);
    updateHistory(items.join("\t"));

    async function findDir(dir) {
      console.log(`/${dir}`);
      if (dir === "/") {
        return fileSystem;
      }

      const dirArr = dir.split("/");
      let dirObj = fileSystem;
      for (let i = 1; i < dirArr.length; i++) {
        const foundItem = dirObj.items.find((item) => item.name === dirArr[i]);
        if (!foundItem || !foundItem.isDirectory) {
          return null;
        }
        dirObj = foundItem;
      }
      console.log(`${dirObj.name}`);
      return dirObj;
    }
  }

  function handlePing(url) {
    if (url.length !== 1) {
      updateHistory("ping command takes exactly one argument.");
      return;
    }

    const startTime = performance.now();

    fetch(`https://${url}/ping`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => console.log(res.json()))
      .then((response) => {
        const endTime = performance.now();
        const timeTaken = (endTime - startTime).toFixed(2);
        updateHistory(`Response from ${url}: status code ${response.status}, time=${timeTaken}ms`);
      })
      .catch((error) => {
        console.log(error);
        updateHistory(`ping: cannot resolve ${url}: Unknown host`);
      });
  }

  function printAsciiArt() {
    setHistory([
      ...history,
      "",
      "⡆⣐⢕⢕⢕⢕⢕⢕⢕⢕⠅⢗⢕⢕⢕⢕⢕⢕⢕⠕⠕⢕⢕⢕⢕⢕⢕⢕⢕⢕",
      "⢐⢕⢕⢕⢕⢕⣕⢕⢕⠕⠁⢕⢕⢕⢕⢕⢕⢕⢕⠅⡄⢕⢕⢕⢕⢕⢕⢕⢕⢕",
      "⢕⢕⢕⢕⢕⠅⢗⢕⠕⣠⠄⣗⢕⢕⠕⢕⢕⢕⠕⢠⣿⠐⢕⢕⢕⠑⢕⢕⠵⢕",
      "⢕⢕⢕⢕⠁⢜⠕⢁⣴⣿⡇⢓⢕⢵⢐⢕⢕⠕⢁⣾⢿⣧⠑⢕⢕⠄⢑⢕⠅⢕",
      "⢕⢕⠵⢁⠔⢁⣤⣤⣶⣶⣶⡐⣕⢽⠐⢕⠕⣡⣾⣶⣶⣶⣤⡁⢓⢕⠄⢑⢅⢑",
      "⠍⣧⠄⣶⣾⣿⣿⣿⣿⣿⣿⣷⣔⢕⢄⢡⣾⣿⣿⣿⣿⣿⣿⣿⣦⡑⢕⢤⠱⢐",
      "⢠⢕⠅⣾⣿⠋⢿⣿⣿⣿⠉⣿⣿⣷⣦⣶⣽⣿⣿⠈⣿⣿⣿⣿⠏⢹⣷⣷⡅⢐",
      "⣔⢕⢥⢻⣿⡀⠈⠛⠛⠁⢠⣿⣿⣿⣿⣿⣿⣿⣿⡀⠈⠛⠛⠁⠄⣼⣿⣿⡇⢔",
      "⢕⢕⢽⢸⢟⢟⢖⢖⢤⣶⡟⢻⣿⡿⠻⣿⣿⡟⢀⣿⣦⢤⢤⢔⢞⢿⢿⣿⠁⢕",
      "⢕⢕⠅⣐⢕⢕⢕⢕⢕⣿⣿⡄⠛⢀⣦⠈⠛⢁⣼⣿⢗⢕⢕⢕⢕⢕⢕⡏⣘⢕",
      "⢕⢕⠅⢓⣕⣕⣕⣕⣵⣿⣿⣿⣾⣿⣿⣿⣿⣿⣿⣿⣷⣕⢕⢕⢕⢕⡵⢀⢕⢕",
      "⢑⢕⠃⡈⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⢃⢕⢕⢕",
      "⣆⢕⠄⢱⣄⠛⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠿⢁⢕⢕⠕⢁",
      "⣿⣦⡀⣿⣿⣷⣶⣬⣍⣛⣛⣛⡛⠿⠿⠿⠛⠛⢛⣛⣉⣭⣤⣂⢜⠕⢑⣡⣴⣿",
      "",
    ]);
  }

  return (
    <div className="terminal">
      <div className="terminal-header">
        <span className="terminal-header-button terminal-header-button-close"></span>
        <span className="terminal-header-button terminal-header-button-minimize"></span>
        <span className="terminal-header-button terminal-header-button-expand"></span>
        <span className="terminal-header-title">danial's Terminal</span>
      </div>
      <div className="terminal-body">
        {history.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
        <div className="terminal-error">{error}</div>

        <form onSubmit={handleInput} className="terminal-form">
          <div className="date-time">{strftime("%H:%M", Date.now())}</div>
          <span className="terminal-prompt">{currentDirectory}&nbsp;➜&nbsp;</span>
          <input className="terminal-input" type="text" autoComplete="off" autoFocus />
        </form>
      </div>
    </div>
  );
}

function strftime(sFormat, date) {
  if (!(date instanceof Date)) date = new Date();
  var nDay = date.getDay(),
    nDate = date.getDate(),
    nMonth = date.getMonth(),
    nYear = date.getFullYear(),
    nHour = date.getHours(),
    aDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    aMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    isLeapYear = function () {
      if ((nYear & 3) !== 0) return false;
      return nYear % 100 !== 0 || nYear % 400 === 0;
    },
    getThursday = function () {
      var target = new Date(date);
      target.setDate(nDate - ((nDay + 6) % 7) + 3);
      return target;
    },
    zeroPad = function (nNum, nPad) {
      return ("" + (Math.pow(10, nPad) + nNum)).slice(1);
    };
  return sFormat.replace(/%[a-z]/gi, function (sMatch) {
    return (
      {
        "%a": aDays[nDay].slice(0, 3),
        "%A": aDays[nDay],
        "%b": aMonths[nMonth].slice(0, 3),
        "%B": aMonths[nMonth],
        "%c": date.toUTCString(),
        "%C": Math.floor(nYear / 100),
        "%d": zeroPad(nDate, 2),
        "%e": nDate,
        "%F": date.toISOString().slice(0, 10),
        "%G": getThursday().getFullYear(),
        "%g": ("" + getThursday().getFullYear()).slice(2),
        "%H": zeroPad(nHour, 2),
        "%I": zeroPad(((nHour + 11) % 12) + 1, 2),
        "%j": zeroPad(aDayCount[nMonth] + nDate + (nMonth > 1 && isLeapYear() ? 1 : 0), 3),
        "%k": "" + nHour,
        "%l": ((nHour + 11) % 12) + 1,
        "%m": zeroPad(nMonth + 1, 2),
        "%M": zeroPad(date.getMinutes(), 2),
        "%p": nHour < 12 ? "AM" : "PM",
        "%P": nHour < 12 ? "am" : "pm",
        "%s": Math.round(date.getTime() / 1000),
        "%S": zeroPad(date.getSeconds(), 2),
        "%u": nDay || 7,
        "%V": (function () {
          var target = getThursday(),
            n1stThu = target.valueOf();
          target.setMonth(0, 1);
          var nJan1 = target.getDay();
          if (nJan1 !== 4) target.setMonth(0, 1 + ((4 - nJan1 + 7) % 7));
          return zeroPad(1 + Math.ceil((n1stThu - target) / 604800000), 2);
        })(),
        "%w": "" + nDay,
        "%x": date.toLocaleDateString(),
        "%X": date.toLocaleTimeString(),
        "%y": ("" + nYear).slice(2),
        "%Y": nYear,
        "%z": date.toTimeString().replace(/.+GMT([+-]\d+).+/, "$1"),
        "%Z": date.toTimeString().replace(/.+\((.+?)\)$/, "$1"),
      }[sMatch] || sMatch
    );
  });
}

export default Terminal;
